import works from '../../data/works'
import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import DetailsCard from '../../components/detailsCard/DetailsCard'

function WorkDetail() {
    const location = useLocation()
    const id = location.state.id
    const [singleWork, setSingleWork] = useState([])

    useEffect(() => {
        const handleDetails = () => {
            let one = works.data.filter(work => work.id === id)
            setSingleWork(one)
            console.log(one)
        }
        handleDetails()
    }, [id])

    return (
        <>
            {
                singleWork.map((item, key) => (
                    <DetailsCard
                        key={key}
                        image={
                            item.image ? < img src={item.image} style={{ width: '100%' }} height={"auto"} alt='bu bir resimdir' /> :

                                null
                        }
                        image2={
                            item.image2 ? < img src={item.image2} style={{ width: '100%' }} height={"auto"} alt='bu bir resimdir' /> :
                                null
                        }
                        image3={
                            item.image3 ? <img src={item.image3} style={{ width: '100%' }} height={"auto"} alt='bu bir resimdir' /> :
                                null
                        }
                        image4={
                            item.image4 ? <img src={item.image4} style={{ width: '100%' }} height={"auto"} alt='bu bir resimdir' /> :
                                null
                        }
                        image5={
                            item.image5 ? <img src={item.image5} style={{ width: '100%' }} height={"auto"} alt='bu bir resimdir' /> :
                                null
                        }
                        image6={
                            item.image6 ? <img src={item.image6} style={{ width: '100%' }} height={"auto"} alt='bu bir resimdir' /> :
                                null
                        }
                        title={item.title}
                        subtitle={item.subtitle}

                        turkishContent={item.detailed_content_turkish ? <p>{item.detailed_content_turkish}</p> : null}
                        englishContent={item.detailed_content_english ? <p>{item.detailed_content_english}</p> : null}
                        videop={item.video ? <p>Click here and send an email to get the required password to watch the video
                        </p> : null}
                        video={item.video ? <iframe title="vimeo-player" src={item.video} width="80%" height="400" frameborder="0" allowfullscreen></iframe> : null}
                    />
                ))
            }
        </>
    )
}

export default WorkDetail