import './Footer.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faVimeoV } from '@fortawesome/free-brands-svg-icons'


function Footer() {
    return (
        <div className='d-flex flex-row justify-content-center footer-text'>
            <div className='text-center '>©  2023. All rights reserved by NUR BARDAKÇI.  </div>
            <div className='insta'>
                <a className='icon' href='https://www.instagram.com/nurbardakci_/'> <FontAwesomeIcon icon={faInstagram} /></a>
                <a className='icon' href='https://vimeo.com/nurbardakci'> <FontAwesomeIcon icon={faVimeoV} /></a>
            </div>
        </div>

    )
}

export default Footer