import './AboutMe.css'
import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer'

function AboutMe() {
    return (

        <div className='d-flex flex-column justify-content-between'>
            <Header />

            <div className='container '>
                <h2 className='title-about'> Nur Bardakçı</h2>
                <h3 className='title-1'>May 27, born in Konya.</h3>
                <h4 className='title-2'>Nothing extraordinary was seen in her childhood.</h4>
                <h4 className='title-2'>Lives and works in Istanbul…</h4>
                <p className='about-me-start'>“Amidst a wearying conflict, we may yearn for a moment to catch our breath and resume where we left off, but such an opportunity rarely materializes…</p>
                <p className='about-me-start'>Similar to how we cannot request a 'time out' before dying or a 'reconsideration' before birth... </p>
                <p className='about-me-start'>As we approach the beginning of life or when we are already living, we cannot seek a 'respite' from the unfolding events or circumstances..."</p>


                <p className='about-me-p'>The subjects that the artist concentrates on find themselves at the heart of this longing. This situation involves transforming the process and perception of the action, previously confined within its own space, into a new concept, allowing the motion to break free from the constraints of the photo frame. The impulse to disentangle from overthinking and embrace simplicity seeks to convey the inherent complexity, as the saying goes. Her works, revolving around themes like mental illnesses, the human anatomy of gentrification, selectivity in perception, and the observation of selective perception processes, adopt a language of self-expression tailored to their respective subject matter.</p>
                <p className='about-me-p'>This effort involves intertwining the wreckage of the past with present memories, exploring the ramifications of reality and unending longing through emotional states, and subjecting the other to the resulting repercussions. These themes are conveyed through approaches such as 'anxiety, fear, and worry…”</p>
                <h4 className='about-me-sub'>Academic</h4>
                <div className='about-me-p'>
                    2016-2022, MA (Master Program) of Plastic Art in Institute of Fine Arts, Yeditepe University, İstanbul
                    <br /> Subject: “Content analysis on trauma and uneasiness in video-art”,
                    <br />
                    2006-2010, Bachelor of Fine Art in Fine Art Faculty of Education, Selcuk University, Konya, Türkiye
                    <br />
                    2002- 2006, Fine Art High School, Konya Fine Art High School, Konya, Türkiye
                </div>
                <h4 className='about-me-sub'>Group Exhibitions</h4>
                <div className='about-me-p'>
                    2023, “Conatus”, Akbank Sanat, İstanbul <br />
                    2023, “Tanıklık”, ALİKEV, İstanbul <br />
                    2021, "Senkron Video Exhibitions- Darağaç/Darmekan", Izmir <br />
                    2020, "Isolated Project", Artist Experience, Istanbul <br />
                    2020, 'Sub!!!', RJNR Project, Çanakkale <br />
                    2018, “Artist Experience-18, TUYAP Art Fair, Tasarım Bakkalı,
                    Istanbul<br />
                    2018, “Open Studio Days”, Istanbul <br />
                    2017, “Donut Disturb”, Gerrit Rietveld Akademie, Amsterdam, Germany <br />

                    2016, “7.Internatioanl Student Trienal”,Marmara University, Istanbul<br />
                    2015, “Zührevi Hastalıklar Odası-X”, Videoart-Jen inisiyatif, Istanbul<br />
                    2015, “Art Amalgamation”, Vadadora, India<br />
                    2015, “Art Beyond Boundaries”, India<br />
                    2015, “Chamber of Venereal Diseases-X”, Videoart-Jen initiative, Italy,
                    Istanbul<br />
                    2014, “Amalgamation”, India<br />
                    2012, “Relativity Cluster”, bi’nisiyatif, Istanbul<br />
                    2012, “Luxury and Luxur”, Boutique 1, v-space, 4‘37”<br />
                    2011, “The Dimensions of the Fugitive”, Gallery Espas – Istanbul<br />

                </div>
                <h4 className='about-me-sub'>Artist in Residence</h4>
                <div className='about-me-p'>

                    2018, “To Be Announced”, European Exchange Academy, Ort Böckenberg/ Gerswalde- Berlin, Germany
                    <br />
                    2016, “Keep Our Planet Alive” Uttarayan Art Foundation, Tellus Art Academy, Vadadora, India<br />
                </div>
                <h4 className='about-me-sub'>Selected Seminars & Artist Talk</h4>
                <div className='about-me-p'>
                    2022, “Protection of Culture and Art productions and copyright issiues”, Halic University, Medipol University, Istanbul<br />
                    2020, Artist Talks, “SUB”project, “video location”, Istanbul <br />
                    2019, Artist Talks, “IZole”project, “we’re family”, Istanbul <br />
                    2017, “Any Kind of story”, Berlin,Germany <br />
                    2014, “Art and Interaction-New Trend Processes / - Conflicts and
                    Resolution of Violence: Aesthetic Values and Transfiguration Processes”, Istanbul, 12-13 July 2014 <br />

                </div>
                <h4 className='about-me-sub'>Awards, Grants, Fellowships</h4>
                <div className='about-me-p'>
                    2023, "Akbank 41st Contemporary Artists Award", Akbank Sanat, Istanbul <br />
                    2023, "Testimony", ALIKEV, Istanbul <br />
                    2022, "City Gardens", Sivil Düşün <br />

                </div>

                <h4 className='about-me-sub'>
                    Experiences Of Projects
                </h4>
                <div className='about-me-p pb-5'>
                    2021, “Zula”, Film, Director <br />
                    2020, 6.Children And Youth Art Biennial, Video Curator (Selection committee), Istanbul <br />
                    2019, “Nyrnphea” Cinema Film Project, Line Producer <br />
                    2018, 5.Children And Youth Art Biennial, Coordinator, Istanbul - Cyprus <br />
                    2013, “Caudle”, bigaleri, Curator, Istanbul <br />
                    2012, “Leave your space”, bigaleri, Curator, Istanbul <br />
                </div >
            </div >
            <Footer />
        </div >
    )
}

export default AboutMe