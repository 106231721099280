import './Home.css'
import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer'
import HomePageVideo from '../../videos/homePageVideo.mp4'
import works from '../../data/works'
import WorksCard from '../../components/worksCard/WorksCard'
import { useNavigate } from 'react-router-dom'

function Home() {
    const navigate = useNavigate()
    const toWorkDetails = (id) => {
        navigate('/workDetail', { state: { id: id } })
    }
    return (
        <div className='background-video'>
            <div className='just-video'>
                <video autoPlay loop muted >
                    <source src={HomePageVideo} type='video/mp4' />
                </video>
            </div>
            <div className='d-flex flex-column justify-content-between'>
                <div className='top-header d-flex flex-column justify-content-center '>
                    <Header />
                </div>
                <div className='home-works-cards container-fluid'>
                    {works.data.map((work, key) => (
                        <WorksCard
                            key={key}
                            title={work.title}
                            subtitle={work.subtitle}
                            coverImage={work.cover_image}
                            onClick={() => toWorkDetails(work.id)}
                            loading="lazy"
                        />
                    ))}
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default Home