import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Home from '../pages/home/Home'
import Exhibitions from '../pages/exhibitions/Exhibitions'
import Collective from '../pages/collective/Collective'
import Contact from '../pages/contact/Contact'
import WorkDetail from '../pages/workDetail/WorkDetail'
import Paintings from '../pages/paintings/Paintings'
import Sketches from '../pages/sketches/Sketches'
import Photographs from '../pages/photographs/Photographs'
import Videos from '../pages/videos/Videos'
import AboutMe from '../pages/aboutMe/AboutMe'

function Navigation() {
    return (
        <Routes forceRefresh={true}>
            <Route path="/" element={<Home />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/workDetail' element={<WorkDetail />} />
            <Route path='/exhibitions' element={<Exhibitions />} />
            <Route path='/collective' element={<Collective />} />
            <Route path='/aboutMe' element={<AboutMe />} />
            <Route path='/paintings' element={<Paintings />} />
            <Route path='/sketches' element={<Sketches />} />
            <Route path='/photographs' element={<Photographs />} />
            <Route path='/videos' element={<Videos />} />
        </Routes>
    )
}

export default Navigation