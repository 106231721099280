import './Sketches.css'
import { useState, useEffect } from 'react'
import WorksCard from '../../components/worksCard/WorksCard'
import { useNavigate } from 'react-router-dom'
import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer'
import works from '../../data/works'

function Sketches() {

    const navigate = useNavigate()
    const toWorkDetails = (id) => {
        navigate('/workDetail', { state: { id: id } })
    }
    const [onlySketches, setOnlySketches] = useState([])
    useEffect(() => {
        const handleSketches = () => {
            let one = works.data.filter(work => work.type === "sketch")
            setOnlySketches(one)
        }
        handleSketches()
    }, [])


    return (
        <div>
            <div className='d-flex flex-column justify-content-between'>
                <div className='d-flex flex-column justify-content-between'>
                    <Header />
                </div>
                <div className='home-works-cards1 container-fluid'>
                    {onlySketches.map((work, key) => (
                        <WorksCard
                            key={key}
                            title={work.title}
                            subtitle={work.subtitle}
                            coverImage={work.cover_image}
                            onClick={() => toWorkDetails(work.id)}
                            loading="lazy"
                        />
                    ))}
                </div>
                <Footer />
            </div>
        </div>
    )
}

export default Sketches