import './Exhibitions.css'
import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer'
import os1 from '../../images/Os/os-1.png'
import os2 from '../../images/Os/os-2.png'
import os3 from '../../images/Os/os-3.png'
import os4 from '../../images/Os/os-4.png'
import tuyap1 from '../../images/Tuyap/tuyap-1.png'
import tuyap2 from '../../images/Tuyap/tuyap-2.png'
import tuyap3 from '../../images/Tuyap/tuyap-3.png'
import donut from '../../images/donut.png'
import mar1 from '../../images/Marmara/mar-1.png'
import mar2 from '../../images/Marmara/mar-2.png'
import mar3 from '../../images/Marmara/mar-3.png'
import co1 from '../../images/Co/co.png'
import co2 from '../../images/Co/co2.png'
import trust1 from '../../images/Trust/trust.png'
import trust2 from '../../images/Trust/trust2.png'
import after from '../../images/after.png'

function Exhibitions() {
    return (
        <div>
            <Header />
            <div className='d-flex flex-column container'>
                <h3 className='ex-title'>“Donut Disturb”, Reitveld Pavillion, view from the exhibition 2020</h3>
                <img src={donut} alt='donut' />
                <h3 className='ex-title'>“open studio days”, view from artist’s studio, live performance, 2019</h3>
                <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img className="d-block w-100  img-fluid " src={os1} alt="First slide" />
                        </div>
                        <div className="carousel-item">
                            <img className="d-block w-100  img-fluid " src={os2} alt="Second slide" />
                        </div>
                        <div className="carousel-item">
                            <img className="d-block w-100   img-fluid " src={os3} alt="Third slide" />
                        </div>
                        <div className="carousel-item">
                            <img className="d-block w-100  img-fluid" src={os4} alt="Third slide" />
                        </div>
                    </div>
                    <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="sr-only">Previous</span>
                    </a>
                    <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="sr-only">Next</span>
                    </a>
                </div>
                <h3 className='ex-title'>“on comfort zone”, Exhibition view from Germany/ Amsterdam and Berline, view from EEA, 2017-2019</h3>
                <div id="carouselExample" className="carousel slide" data-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img className="d-block w-100  img-fluid " src={co1} alt="First slide" />
                        </div>
                        <div className="carousel-item">
                            <img className="d-block w-100  img-fluid " src={co2} alt="Second slide" />
                        </div>
                    </div>
                    <a className="carousel-control-prev" href="#carouselExample" role="button" data-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="sr-only">Previous</span>
                    </a>
                    <a className="carousel-control-next" href="#carouselExample" role="button" data-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="sr-only">Next</span>
                    </a>
                </div>
                <h3 className='ex-title'>“trust me”, Exhibition view from Germany/ Amsterdam and Berline, view from EEA, 2017-2019</h3>
                <div id="trust" className="carousel slide" data-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img className="d-block w-100  img-fluid " src={trust1} alt="First slide" />
                        </div>
                        <div className="carousel-item">
                            <img className="d-block w-100  img-fluid " src={trust2} alt="Second slide" />
                        </div>
                    </div>
                    <a className="carousel-control-prev" href="#trust" role="button" data-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="sr-only">Previous</span>
                    </a>
                    <a className="carousel-control-next" href="#trust" role="button" data-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="sr-only">Next</span>
                    </a>
                </div>
                <h3 className='ex-title'>“after Libken”, Exhibition view from Germany/ Amsterdam and Berline, view from EEA, 2017-2019</h3>
                <img src={after} alt='donut' />
                <h3 className='ex-title'>TUYAP, installation views from Tasarım Bakkalı, 2018</h3>
                <div id="carouselExampleControl" className="carousel slide" data-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img className="d-block w-100  img-fluid " src={tuyap1} alt="Firstslide" />
                        </div>
                        <div className="carousel-item">
                            <img className="d-block w-100  img-fluid " src={tuyap2} alt="Secondslide" />
                        </div>
                        <div className="carousel-item">
                            <img className="d-block w-100   img-fluid " src={tuyap3} alt="Thirdslide" />
                        </div>
                    </div>
                    <a className="carousel-control-prev" href="#carouselExampleControl" role="button" data-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="sr-only">Previous</span>
                    </a>
                    <a className="carousel-control-next" href="#carouselExampleControl" role="button" data-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="sr-only">Next</span>
                    </a>
                </div>
                <h3 className='ex-title'>Marmara Üniversitesi , GSF, Sanatçı Günlükleri, Defter No: 259, 2017</h3>
                <div id="carouselExampleContrl" className="carousel slide" data-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item active">
                            <img className="d-block w-100  img-fluid " src={mar1} alt="Firstslide" />
                        </div>
                        <div className="carousel-item">
                            <img className="d-block w-100  img-fluid " src={mar2} alt="Secondslide" />
                        </div>
                        <div className="carousel-item">
                            <img className="d-block w-100   img-fluid " src={mar3} alt="Thirdslide" />
                        </div>
                    </div>
                    <a className="carousel-control-prev" href="#carouselExampleContrl" role="button" data-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="sr-only">Previous</span>
                    </a>
                    <a className="carousel-control-next" href="#carouselExampleContrl" role="button" data-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="sr-only">Next</span>
                    </a>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Exhibitions