import './DetailsCard.css'
import Header from '../header/Header'
import Footer from '../footer/Footer'

function DetailsCard({ title, subtitle, image, image2, image3, image4, image5, image6, turkishContent, englishContent, videop, video }) {

    return (
        <div >
            <Header />
            <div className='d-flex flex-column container'>
                <div className='col-12 col-sm-12 col-md-12'>
                    <div className='title-detail '>
                        {title}, {subtitle}
                    </div>
                </div>
                <div className='other-img mb-5'>
                    {image}
                    {image2}
                    {image3}
                    {image4}
                    {image5}
                    {image6}
                </div>
                <div className='title-detail-2'>
                    {title},{subtitle}
                </div>

                <div className='text-detail'>
                    {englishContent}
                </div>
                <div className='text-detail'>
                    {turkishContent}
                </div>
                <a className='mailto' href="mailto:nurbardakci@gmail.com">{videop}</a>
                <div className='text-center pb-5'>{video}</div>
            </div>
            <Footer />
        </div>
    )
}

export default DetailsCard