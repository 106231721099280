import './Collective.css'
import Header from '../../components/header/Header'
import Footer from '../../components/footer/Footer'

function Collective() {
    return (
        <div>
            <Header />
            <div className='container'>
                <h3 className='title-collective'>“darling we are rising- spiritus asper”, clip, 2021 </h3>
                <iframe width="100%" height="500" src="https://www.youtube.com/embed/mSai1WXJZxQ" title="spiritus asper - darling we are rising" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <h3 className='title-collective'>“Kadim Longoz-İğneada”,video-film, 2021</h3>
                <iframe width="100%" height="500" src="https://www.youtube.com/embed/H5scMLioQkw" title="Ormanın Türküsü - Kadim Longoz" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <h3 className='title-collective'>“Kadim Longoz-İğneada”,video-film, 2021</h3>
                <iframe width="100%" height="500" src="https://www.youtube.com/embed/Xk7CeW3LZYc" title="Kadim Longoz İğneada" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <h3 className='title-collective'>“Cinatı: Bir CriticalMass Hikayesi”,CriticalMass Turkey, 2021</h3>
                <iframe width="100%" height="500" src="https://www.youtube.com/embed/kpgLuIWtWY4" title="Cinatı: Bir CriticalMass Hikayesi -CriticalMass Turkey-" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <h3 className='title-collective'>“Temiz Bir Çiftlik”, Kinocycle, video-film, 2020</h3>
                <iframe width="100%" height="500" src="https://www.youtube.com/embed/cRxTzkmIpQo" title="Temiz Bir Çiftlik" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <h3 className='title-collective'>Açık Stüdyo Günleri Röportajları - Nur Bardakçı, 2019 </h3>
                <iframe width="100%" height="500" src="https://www.youtube.com/embed/F4XtTN0wVmg" title="Açık Stüdyo Günleri Röportajları - Nur Bardakçı" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                <h3 className='title-collective'>“Lohusa Şerbeti- 1/2 Concentration”, exhibition, view from video, 2014 </h3>
                <iframe src="https://player.vimeo.com/video/121029981?h=a306662243" width="100%" height="500" frameborder="0" title="lohusa serbeti - Nur Bardakçı" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
            </div>
            <Footer />

        </div>
    )
}

export default Collective