import './WorksCard.css'

function WorksCard({ coverImage, onClick, title, subtitle }) {

    return (

        <div onClick={onClick} className='images-bg col-10 col-sm-10 col-md-5 col-lg-3' style={{ backgroundImage: `url(${coverImage})` }} >
            <div className=' works-name d-block d-sm-block d-md-block d-lg-none'>
                <div className='title-s'>{title}</div>

                <div className='subtitle-s'>{subtitle}</div>
            </div>
            <div className='overlay d-none d-sm-none d-md-none d-lg-block'>
                <div className='text'>{title}</div>
                <div className='sub-text'>{subtitle}</div>
            </div>
        </div>
    )
}

export default WorksCard

